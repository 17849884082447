import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";
import { toast } from "react-toastify";
const redirect_uri = process.env.NODE_ENV == "production" ? "https://peter-lee-gifford.com" : "http://localhost:3000"
const base_url = process.env.NODE_ENV == "production" ? "https://hooks-28xd.onrender.com" : "http://localhost:4000"

const Tss = () => {
  const navigate = useNavigate();
  const [cookies, removeCookie] = useCookies([]);
  const [username, setUsername] = useState("");
  const [searchParams] = useSearchParams();
  const [token, setToken] = useState(""); 
  const [activites, setActivites] = useState({});
  const [userStats, setUserStats] = useState({});
  const strava_url = "http://www.strava.com/oauth/authorize?client_id=106608&response_type=code&redirect_uri=" + redirect_uri + "/tss&approval_prompt=force&scope=activity:read_all,profile:read_all"
  useEffect(() => {
    const verifyCookie = async () => {
      if (!cookies.token) {
        navigate("/login");
      }
      const { data } = await axios.post(
        base_url + "/auth/tss",
        {},
        { withCredentials: true,
          httpOnly: false,
          sameSite: "none",
          secure: true
         }
      );
      const { status, user } = data;
      setUsername(user);
      return status
        ? toast(`Hello ${user}`, {
            position: "top-right",
          })
        : (removeCookie("token"), navigate("/login"));
    };
    verifyCookie();
    const handleAuthCode = async () => {
      const code = searchParams.get('code')
      if (code !== null && code !== "") {
        try {
          const {data} = await axios.post(
            base_url + "/auth/strava_auth",
            {
              "code": code
            }
          )
          setToken(data.access_token)
        }catch(error) {
          console.log(error)
        }
      }
    }
    handleAuthCode();

  }, [cookies, navigate, removeCookie, searchParams]);
  const Logout = () => {
    removeCookie("token");
    navigate("/signup");
  };
  const fetchStravaActivities = async() => {
    try {
    const activities = await axios.get("https://www.strava.com/api/v3/athlete/activities", { headers: { Authorization: "Bearer " + token}})
    setActivites(activities)
    } catch (error) {
      console.log(error)
    }
  };
  const fetchStravaAthleteInfo = async() => {
    try {
      const activityZones = await axios.get("https://www.strava.com/api/v3/athlete/zones", { headers: { Authorization: "Bearer " + token}})
      setUserStats(activityZones)
      } catch (error) {
        console.log(error)
      }
  }
  const getLastWeekMaxPower = () => {
    var max_bike_power = 0
    if (activites.data) {
      activites.data.forEach(activity => {
        if((activity.type === "Ride" || activity.type === "VirtualRide") && activity.max_watts > max_bike_power) {
          max_bike_power = activity.max_watts
        }
      });
    }
    return max_bike_power;
  }
  const getLastWeekMaxRunSpeed = () => {
    var max_run_speed = 0
    if (activites.data) {
      activites.data.forEach(activity => {
        if(activity.type === "Run" && activity.max_speed > max_run_speed) {
          max_run_speed = activity.max_speed
        }
      });
    }
    return max_run_speed;
    
  }
  const getLastWeekMaxSwimSpeed = () => {
    var max_swim_speed = 0
    if (activites.data) {
      activites.data.forEach(activity => {
        if(activity.type === "Swim" && activity.max_speed > max_swim_speed) {
          max_swim_speed = activity.max_speed
        }
      });
    }
    return max_swim_speed;
    
  }
  const getLastWeekTotalHours = () => {
    var total_hours = 0
    if (activites.data) {
      activites.data.forEach(activity => {
        total_hours += activity.elapsed_time
      });
    }
    return total_hours;
    
  }
  const getLastWeekLongestActivity = () => {
    var longest_activity = 0
    if (activites.data) {
      activites.data.forEach(activity => {
        if (activity.elapsed_time > longest_activity)
        longest_activity = activity.elapsed_time
      });
    }
    return longest_activity;
    
  }
  return (
    <>
      <div className="home_page">
        <div class="grid-item">
          <span>{username}</span>
        </div>
        <div class="grid-item">
          <a href={strava_url}>Auth Strava</a>
          <br />
          <button onClick={() => fetchStravaActivities()}>Fetch Activites</button>
          <br />
          <button onClick={() => fetchStravaAthleteInfo()}>Fetch Zones</button>
        </div>
        <div class="grid-item">
          <button onClick={Logout}>LOGOUT</button>
        </div>

        <div className="grid-item">
          <p>FTP</p>
          <p>
            TODO: Will Store
          </p>
        </div>
        <div className="grid-item">
          <p>One Mile PR</p>
          <p>
            TODO: Store, Aggregate from Strava
          </p>
        </div>
        <div className="grid-item">
          <p>Swim 100 Yards PR</p>
          <p>
            TODO: Store, Aggregate from Strava 
          </p>
        </div>
      </div>


      <div>
        Goals Focuses
      </div>
      <div className="home_page">
        <div className="grid-item">
            <p>Tempo Pace Run</p>
            <p>
            TODO: Will Store
            </p>
          </div>
          <div className="grid-item">
            <p>Interval Pace Run</p>
            <p>
              TODO: Will Store
            </p>
          </div>
          <div className="grid-item">
            <p>Swim Interval Pace</p>
            <p>
              TODO: Will Store
            </p>
          </div>

          <div className="grid-item">
            <p>Bike Tempo Pace</p>
            <p>
            TODO: Will Store
            </p>
          </div>
          <div className="grid-item">
            <p>Bike Interval Pace</p>
            <p>
              TODO: Will Store
            </p>
          </div>
      </div>

      <div>Recent Info</div>
      <div className="home_page">
        <div className="grid-item">
            <p>Previous Top Bike Power</p>
            <p>
              {getLastWeekMaxPower()}
            </p>
          </div>
          <div className="grid-item">
            <p>Previous Top Run Speed</p>
            <p>
              {getLastWeekMaxRunSpeed()}
            </p>
          </div>
          <div className="grid-item">
            <p>Previous Top Swim Speed</p>
            <p>
              {getLastWeekMaxSwimSpeed()}
            </p>
          </div>

          <div className="grid-item">
            <p>Last week total hours</p>
            <p>
              {getLastWeekTotalHours()}
            </p>
          </div>
          <div className="grid-item">
            <p>Last week longest activity per sport</p>
            <p>
              {getLastWeekLongestActivity()}
            </p>
          </div>
      </div>
    </>
  );
};

export default Tss;