import './App.css';
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Whale from './pages/Whale';
import Tss  from './pages/Tss';
import Login from './pages/Login';
import Signup from './pages/Signup';


function App() {
  return (
    <Router>
        <Routes>
          <Route path="/" element={<Whale />} />
          <Route path="/tss" element={ <Tss />} />
          <Route path="/login" element={ <Login />} />
          <Route path="/signup" element={ <Signup />} />
        </Routes>
    </Router>
  );
}


export default App;