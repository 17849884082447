import '../App.css';
import React from "react";
import { useEffect, useMemo, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim"; 

function Whale() {
  const [init, setInit] = useState(false);
  const [pushMessage, setPushMessage] = useState("");
  const whale = "    .-------------'```'----....,,__                        _,\n  |                               `'`'`'`'-.,.__        .'(\n  |                                             `'--._.'   )\n  |                                                   `'-.<\n  \\               .-'`'-.                            -.    `\\\n   \\               -.o_.     _                     _,-'`\\    |\n    ``````''--.._.-=-._    .'  \\            _,,--'`      `-._(\n      (^^^^^^^^`___    '-. |    \\  __,,..--'                 `\n       `````````   `'--..___\\    |`\n                             `-.,'\n";

  // Fetching message from backend on mount
  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
    // fetch("https://hooks-28xd.onrender.com")

    const events = new EventSource("https://hooks-28xd.onrender.com/pushListener");
    events.onmessage = (event) => {
      setPushMessage(event.data);
    }
  }, [pushMessage]);

  const particlesLoaded = (container) => {
    console.log(container);
  };

  const options = useMemo(
    () => ({
      background: {
        color: "#000"
      },
      detectRetina: false,
      fpsLimit: 30,
      interactivity: {
        detectsOn: "canvas",
        events: {
          resize: true
        }
      },
      particles: {
        color: {
          value: "#fff"
        },
        number: {
          density: {
            enable: true,
            area: 1080
          },
          limit: 0,
          value: 400
        },
        opacity: {
          animation: {
            enable: true,
            minimumValue: 0.05,
            speed: 0.25,
            sync: false
          },
          random: {
            enable: true,
            minimumValue: 0.05
          },
          value: 1
        },
        shape: {
          type: "circle"
        },
        size: {
          random: {
            enable: true,
            minimumValue: 0.5
          },
          value: 1
        }
      }
    }),
    [],
  );

  return (
    <div className='box'>
      <Particles
        id="tsparticles"
        particlesLoaded={particlesLoaded}
        options={options}
      />
      <h1>{pushMessage}</h1>
      <TextArt
      label="ASCII art"
      text={whale}
      />
    </div>
  );
}

function TextArt ({label, text}) {
  return (
    <pre
      aria-label={label}
      className="text-art"
    >{text}</pre>
  );
}


export default Whale;